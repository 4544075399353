window.addEventListener('load', async () => {
    if (window.ethereum) {
        window.web3 = new Web3(ethereum);
        try {
            await ethereum.enable();
            unlockAdmin();
        } catch (error) {
            // User denied account access
        }
    } else if (window.web3) {
        window.web3 = new Web3(web3.currentProvider);
        unlockAdmin();
    } else {
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
});

function unlockAdmin() {
    web3.eth.getAccounts().then(function (accounts) {
        if (accounts[0] === '0x90b5cFEd2A0bd5D39D97de027C6F29BE6c9f6ef2' || accounts[0] === '0x05573688669FB7e4a6bd0E312c07E19373A5A88D' || accounts[0] === '0x46672AE20A012eE256C69D43f4fA7b7a48B0165F') {
            $('#buttons').append('<a class="btn btn-dark" href="leaderboard/manage" role="button">Leaderboard Manager</a>');
            $('#manager').addClass('me-2');
        }
    });
}
